import React from "react";

// COMPONENTS
import Header from "../base/Header";
import Footer from '../components/Footer';
import Artists from "../components/Artists";

export default function Index() {

    return(

        <div className="content">
            <Header />
            <Artists />
            <Footer />
        </div>

    )

}