let page = window.location.hostname;
let BACK_ENDPOINT = '';

if(page === 'localhost') {

    BACK_ENDPOINT = 'http://localhost/grapps/api/laquintarecords/';

} else {

    BACK_ENDPOINT = 'https://laquintarecords.quintanaysolorzano.com/api/';

}

export { BACK_ENDPOINT }