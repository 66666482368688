import React, { useState, useEffect } from 'react';
import { BrowserRouter, Routes, Route, Navigate } from "react-router-dom";
import $ from 'jquery';
import axios from 'axios';

// BACKEND
import { BACK_ENDPOINT } from './const';

// CSS
import 'bootstrap/dist/css/bootstrap.min.css';

// LAYOUTS
import Navbar from './base/Navbar';
import BookingModal from './components/booking/BookingModal';
import DistributionModal from './components/distribution/DistributionModal';

// PAGES
import Index from './pages/Index';

function App() {

  const [ artists, setArtists ] = useState([]);
  const [ resultText, setResultText ] = useState("");

  useEffect(() => {

    getArtists();

  }, [])

  async function getArtists() {

    try{

      const res = await axios.get(BACK_ENDPOINT + 'artists/');
      setArtists(res.data);

    } catch(err) {

      console.log('Error: ', err)

    }

  }

  function showBookingModal() {

    $('.booking-modal').toggleClass('visible');

  }

  function showDistributionModal() {

    $('.distribution-modal').toggleClass('visible');

  }

  const newBooking = async(data) => {

    try {

      const res = await axios.post(BACK_ENDPOINT + 'booking/', data);
      setResultText("Mensaje recibido. Nos contactaremos con usted a la brevedad posible.");
      return true;

    } catch(err) {

      console.log('Error: ', err);

    }

  }

  const newDistribution = async(data) => {

    try {

      const res = await axios.post(BACK_ENDPOINT + 'distribution/', data);
      setResultText("Mensaje recibido. Nos contactaremos con usted a la brevedad posible.");
      return true;

    } catch(err) {

      console.log('Error: ', err);

    }

  }

  return (
    <div className="App">
      <BrowserRouter>
        <Navbar showBookingModal={ showBookingModal } showDistributionModal={ showDistributionModal } />
        <BookingModal hide={ showBookingModal } artists={ artists } newBooking={ newBooking } resultText={ resultText } />
        <DistributionModal hide={ showDistributionModal } newDistribution={ newDistribution } resultText={ resultText } />
        <Routes>
          <Route path="*" element={ <Navigate to='/' replace /> } />
          <Route path="/" element={ <Index/> } />
        </Routes>
      </BrowserRouter>
    </div>
  );
}

export default App;
