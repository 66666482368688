import React from "react";
import { Link as A } from "react-router-dom";
import { Link, animateScroll as scroll } from "react-scroll";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

// ICONS
import { faInstagram, faFacebook } from "@fortawesome/free-brands-svg-icons";
import { faBars } from "@fortawesome/free-solid-svg-icons";
import $ from 'jquery';

// LOGO
import Logo from '../assets/logo_black.png'

export default function Navbar(props) {

    const show = () => {

        $('.menu-mobile').toggleClass('show-menu-mobile');

    }

    function handleClickBooking() {

        if($('.menu-mobile').hasClass( "show-menu-mobile" )) {

            $('.menu-mobile').removeClass('show-menu-mobile');

        }
        props.showBookingModal();

    }

    function handleClickDistribution() {

        if($('.menu-mobile').hasClass( "show-menu-mobile" )) {

            $('.menu-mobile').removeClass('show-menu-mobile');

        }
        props.showDistributionModal();

    }

    return (

        <nav className="navbar navbar-expand-lg">
            <div className="container-fluid">
                <a className="navbar-brand">
                    <img src={Logo} className="img-fluid ms-3" />
                </a>
                <div className="collapse navbar-collapse">
                    <ul className="navbar-nav mx-auto mb-2 mb-lg-0">
                        <li className="nav-item">
                            <Link to='header' activeClass="active" smooth={true} spy={true} duration={500} offset={-120} className='nav-link'>Inicio</Link>
                        </li>
                        <li className="nav-item">
                            <Link to="artists" activeClass="active" smooth={true} spy={true} duration={500} offset={-120} className='nav-link'>Artistas</Link>
                        </li>
                        <li className="nav-item">
                            <a activeClass="active" onClick={ handleClickDistribution } className='nav-link'>Distribución</a>
                        </li>
                        <li className="nav-item">
                            <a href="https://open.spotify.com/playlist/37i9dQZF1DZ06evO1T1Jcs?si=61bINrQ3TEKqMa7SnifQgQ" rel="noreferrer" target="_blank" className='nav-link'>Playlist</a>
                        </li>
                        <li className="nav-item">
                            <a type="button" onClick={handleClickBooking} activeClass="active" className='nav-link'>Booking</a>
                        </li>
                        <li className="nav-item">
                            <a href='https://veelmusic.com/' rel="noreferrer" target="_blank" className='nav-link'>Colombia</a>
                        </li>
                    </ul>
                </div>
                <div className="d-none d-lg-flex nav-social-media pe-lg-3 row">
                    <a className="col" title="Facebook" href="https://www.facebook.com/profile.php?id=61556243668227" target="_blank" rel="noreferrer">
                        <FontAwesomeIcon icon={faFacebook} />
                    </a>
                    <a className="col" title="Instagram" href="https://www.instagram.com/laquinta_record/" target="_blank" rel="noreferrer">
                        <FontAwesomeIcon icon={faInstagram} />
                    </a>
                </div>
                <div className="d-flex d-lg-none pe-3 pe-md-5">
                    <button className="bg-transparent text-black p-0" onClick={show}>
                        <FontAwesomeIcon icon={faBars} style={{ 'fontSize': '2rem' }} />
                    </button>
                </div>
                <div className="menu-mobile">
                    <div>
                        <ul>
                            <li className="nav-item">
                                <Link to='header' activeClass="active" smooth={true} spy={true} duration={500} offset={-120} onClick={show} className='nav-link'>Inicio</Link>
                            </li>
                            <li className="nav-item">
                                <Link to="artists" activeClass="active" smooth={true} spy={true} duration={500} offset={-120} className='nav-link' onClick={show}>Artistas</Link>
                            </li>
                            <li className="nav-item">
                                <a type="button" onClick={ handleClickDistribution } className='nav-link'>Distribución</a>
                            </li>
                            <li className="nav-item">
                                <a href="https://open.spotify.com/playlist/37i9dQZF1DZ06evO1T1Jcs?si=61bINrQ3TEKqMa7SnifQgQ" rel="noreferrer" target="_blank" onClick={show} className='nav-link'>Playlist</a>
                            </li>
                            <li className="nav-item">
                                <a type="button" onClick={handleClickBooking} className='nav-link'>Booking</a>
                            </li>
                            <li className="nav-item">
                                <a href='https://veelmusic.com/' rel="noreferrer" target="_blank" onClick={show} className='nav-link'>Colombia</a>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        </nav>

    )

}