import React, { useState, useEffect } from "react";
import $ from 'jquery';

export default function BookingModal(props) {

    const { artists, hide, newBooking, resultText } = props;

    const [data, setData] = useState({});
    const [isSubscribed, setIsSubscribed] = useState(false);

    const handleChange = e => {

        setIsSubscribed(e.target.checked);

        if (e.target.name !== "subscribe") {
            setData({
                ...data,
                [e.target.id]: e.target.value,
            });
        }

    }

    const handleSubmit = (e) => {

        e.preventDefault();
        let d = {

            name: data.name,
            phone: data.phone,
            email: data.email,
            request_date: data.request_date,
            artist: data.artist,
            subscribe: isSubscribed

        }
        if (newBooking(d)) {

            $('.result-container').addClass('visible');
            setTimeout(() => {

                $('.result-container').removeClass('visible');

            }, 5000)
            e.target.reset();

        } else {

            console.log('Error');

        }

    }

    return (

        <div className="booking-modal">
            <div className="booking-modal-content">
                <div className="modal-header mb-4">
                    <h3 className="fw-bold text-center mb-0">
                        Booking
                    </h3>
                </div>
                <div className="col-12 col-lg-8 mx-auto px-3 pb-5">
                    <p className="small">
                        Solicita información sobre nuestros artistas.
                    </p>
                    <form onSubmit={handleSubmit}>
                        <div className="form-group mb-3">
                            <input id="name" className="form-control" type="text" onChange={handleChange} required />
                            <label className="form-label" htmlFor="name">
                                Nombre y apellidos
                            </label>
                        </div>
                        <div className="form-group mb-3">
                            <input id="email" className="form-control" type="email" onChange={handleChange} required />
                            <label className="form-label" htmlFor="email">
                                Correo electrónico
                            </label>
                        </div>
                        <div className="form-group mb-3">
                            <input id="phone" className="form-control" type="tel" onChange={handleChange} pattern="[0-9]+" required />
                            <label className="form-label" htmlFor="phone">
                                Teléfono
                            </label>
                        </div>
                        <div className="form-group mb-3">
                            <input id="city" className="form-control" type="text" onChange={handleChange} required />
                            <label className="form-label" htmlFor="city">
                                Ciudad
                            </label>
                        </div>
                        <div className="form-group mb-3">
                            <input id="request_date" className="form-control" type="date" onChange={handleChange} required />
                            <label className="form-label" htmlFor="request_date">
                                Fecha
                            </label>
                        </div>
                        <div className="form-group mb-3">
                            <select id="artist" onChange={handleChange} className="form-control">
                                <option>
                                    -- SELECCIONE --
                                </option>
                                {
                                    artists.length > 0 ?
                                        (
                                            artists.map((artist, i) =>

                                            (
                                                <option value={artist.id} key={i}>
                                                    {artist.name}
                                                </option>
                                            )
                                            )
                                        )

                                        : <option>Vacío</option>
                                }
                            </select>
                            <label className="form-label" htmlFor="artist">Artista</label>
                        </div>
                        <div className="form-group mb-3">
                            <textarea id="comment" onChange={handleChange} className="form-control"></textarea>
                            <label className="form-label" htmlFor="comment">
                                Comentarios
                            </label>
                        </div>
                        <div className="d-flex align-items-start">
                            <input type="checkbox" id="subscribe" checked={ isSubscribed } onChange={ handleChange } className="me-2" />
                            <label htmlFor="subscribe" style={{'display': ''}}>
                                Suscríbete a nuestra newsletter para recibir información actualizada sobre eventos, promociones y más.
                            </label>
                        </div>
                        <div className="bg-red text-white result-container">
                            <span>
                                {
                                    resultText.length > 0 ? (resultText) : ('')
                                }
                            </span>
                        </div>
                        <div className="mt-5 d-flex flex-wrap justify-content-around">
                            <button type="reset" onClick={hide}>
                                CANCELAR
                            </button>
                            <button type="submit">
                                ENVIAR
                            </button>
                        </div>
                    </form>
                </div>
            </div>
        </div>

    )

}