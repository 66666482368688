import React, { useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

// ICONS
import { faInstagram, faFacebook } from '@fortawesome/free-brands-svg-icons';

// LOGO
import LogoWhite from '../assets/logo_white.png';

export default function Footer() {

    return(

        <footer className='text-white position-relative'>
            <div className='position-absolute'>
                <img src={ LogoWhite } width={ 120 } />
            </div>
            <div className='text-center py-3'>
                <div className='footer-social-container'>
                   <a className='text-white' title="Instagram" href="https://www.instagram.com/laquinta_record/" target="_blank" rel="noreferrer">
                        <FontAwesomeIcon icon={ faInstagram } /> 
                   </a>
                   <a className='text-white ms-3' title="Facebook" href="https://www.facebook.com/profile.php?id=61556243668227" target="_blank" rel="noreferrer">
                        <FontAwesomeIcon icon={ faFacebook } /> 
                   </a>
                </div>
                <span>
                    La Quinta Records
                </span>
            </div>
        </footer>

    )

}