import React, { useState } from "react";
import $ from 'jquery';

export default function DistributionModal(props) {

    const { hide, resultText, newDistribution } = props;

    const [data, setData] = useState({});

    const handleChange = e => {

        setData({
            ...data,
            [e.target.id]: e.target.value,
        });

    }

    const handleSubmit = (e) => {

        e.preventDefault();
        if(newDistribution(data)) {

            $('.result-container').addClass('visible');
            setTimeout(() => {

                $('.result-container').removeClass('visible');

            }, 5000)
            e.target.reset();

        } else {

            console.log('Error');

        }

    }

    return (

        <div className="distribution-modal">
            <div className="distribution-modal-content">
                <div className="modal-header mb-4">
                    <h3 className="fw-bold text-center mb-0">
                        Distribución
                    </h3>
                </div>
                <div className="col-12 col-lg-8 mx-auto px-3 pb-5">
                    <p className="small">
                        Contacta un agregador musical.
                    </p>
                    <form onSubmit={handleSubmit}>
                        <div className="form-group mb-3">
                            <input id="name" className="form-control" type="text" onChange={handleChange} required />
                            <label className="form-label" htmlFor="name">
                                Nombre y apellidos
                            </label>
                        </div>
                        <div className="form-group mb-3">
                            <input id="email" className="form-control" type="email" onChange={handleChange} required />
                            <label className="form-label" htmlFor="email">
                                Correo electrónico
                            </label>
                        </div>
                        <div className="form-group mb-3">
                            <input id="phone" className="form-control" type="tel" onChange={handleChange} pattern="[0-9]+" required />
                            <label className="form-label" htmlFor="phone">
                                Teléfono
                            </label>
                        </div>
                        <div className="bg-red text-white result-container">
                            <span>
                                {
                                    resultText.length > 0 ? (resultText) : ('')
                                }
                            </span>
                        </div>
                        <div className="mt-5 d-flex flex-wrap justify-content-around">
                            <button type="reset" onClick={hide}>
                                CANCELAR
                            </button>
                            <button type="submit">
                                ENVIAR
                            </button>
                        </div>
                    </form>
                </div>
            </div>
        </div>

    )

}