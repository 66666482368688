import React from "react";
import { Swiper, SwiperSlide } from 'swiper/react';
import 'animate.css';

// Swipper Styles
import 'swiper/css';

// VIDEO
import Video from '../assets/videos/la-quinta-records-presentation.mp4';

// IMAGE
import LosNenesRub from '../assets/artists/los-nenes-rub-amaya-la-pille.webp';
import LogoWhite from '../assets/logo_white.png';

export default function Header() {

    return(

        <header id='header' className="text-white position-relative">
            <video autoPlay muted loop>
                <source src={ Video } type="video/mp4" />
            </video>
        </header>

    )

}