import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
// ICONS
import { faXTwitter, faInstagram, faSpotify, faTiktok } from "@fortawesome/free-brands-svg-icons";

// IMAGES
import Rub from '../assets/artists/rub-amaya-1080x720.webp';
import Nenes from '../assets/artists/los-nenes-1080x720.webp';
import Cizco from '../assets/artists/cizco_circus.webp';
import Nony from '../assets/artists/nony-1080x720.webp';
import LosInfieles from '../assets/artists/los-infieles-del-vallenato-1080x720.webp';

export default function Artists() {

    return (

        <div id='artists' className="d-flex col-12 flex-wrap border-bottom-red">
            <div className="d-flex col-12 flex-wrap artist-container">
                <div className="col-12 col-lg-6 artist-img-container">
                    <img src={Rub} className="artist-img" />
                    <div className="position-absolute artist-social row justify-content-evenly">
                        <div className="col">
                            <a href="https://www.instagram.com/rubamaya/" target="_blank" rel='noreferrer'>
                                <FontAwesomeIcon icon={faInstagram} />
                            </a>
                        </div>
                        <div className="col">
                            <a href="https://twitter.com/rubamayaa?lang=es" target="_blank" rel='noreferrer'>
                                <FontAwesomeIcon icon={faXTwitter} />
                            </a>
                        </div>
                        <div className="col">
                            <a href='https://www.tiktok.com/@rubamayavzla' target="_blank" rel='noreferrer'>
                                <FontAwesomeIcon icon={faTiktok} />
                            </a>
                        </div>
                        <div className="col">
                            <a href='https://open.spotify.com/intl-es/artist/3fVz0ec5nIY8KOZcQTPICp?si=UlNp33pvSEeq4cOyLzGOww' target="_blank" rel='noreferrer'>
                                <FontAwesomeIcon icon={faSpotify} />
                            </a>
                        </div>
                    </div>
                </div>
                <div className="col-12 col-lg-6 d-flex align-items-center flex-column justify-content-center artist-name-container text-white">
                    <div className="col-12 d-flex align-items-center justify-content-center artist-name-container text-white py-3 bg-red">
                        <h3>
                            RUB AMAYA
                        </h3>
                    </div>
                    <iframe className="w-100 artist-video" src="https://www.youtube.com/embed/4r11i6kqbgk?si=djszVm2gXQecuxcX" title="YouTube video player" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
                </div>
            </div>
            <div className="d-flex col-12 flex-wrap flex-column-reverse flex-lg-row">
                <div className="col-12 col-lg-6 d-flex align-items-center flex-column justify-content-center artist-name-container text-white">
                    <div className="col-12 d-flex align-items-center justify-content-center artist-name-container text-white py-3 bg-red">
                        <h3>
                            LOS INFIELES DEL VALLENATO
                        </h3>
                    </div>
                    <iframe className="w-100 artist-video" src="https://www.youtube.com/embed/4j6Xbh3D3vg?si=PybTUjVo902PWBmj" title="YouTube video player" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
                </div>
                <div className="col-12 col-lg-6 artist-img-container border-top">
                    <img src={LosInfieles} className="artist-img" />
                    <div className="position-absolute artist-social row">
                        <div className="col">
                            <a href="https://www.instagram.com/losinfielesdelvallenatooficial/" target="_blank" rel='noreferrer'>
                                <FontAwesomeIcon icon={faInstagram} />
                            </a>
                        </div>
                        <div className="col">
                            <a href="https://twitter.com/losinfielesv" target="_blank" rel='noreferrer'>
                                <FontAwesomeIcon icon={faXTwitter} />
                            </a>
                        </div>
                        <div className="col">
                            <a href='https://open.spotify.com/intl-es/artist/1p5iqC2dqoGMJwxlwQQ6Q0?si=R0IceB51Rv-cVZzmzAkKqQ' target="_blank" rel='noreferrer'>
                                <FontAwesomeIcon icon={faSpotify} />
                            </a>
                        </div>
                    </div>
                </div>
            </div>
            <div className="d-flex col-12 flex-wrap flex-column flex-lg-row">
                <div className="col-12 col-lg-6 artist-img-container border-top">
                    <img src={Nenes} className="artist-img" />
                    <div className="position-absolute artist-social row">
                        <div className="col">
                            <a href="https://www.instagram.com/losnenesdelacasavzla/" target="_blank" rel='noreferrer'>
                                <FontAwesomeIcon icon={faInstagram} />
                            </a>
                        </div>
                        <div className="col">
                            <a href="https://twitter.com/nenesdelacasa" target="_blank" rel='noreferrer'>
                                <FontAwesomeIcon icon={faXTwitter} />
                            </a>
                        </div>
                        <div className="col">
                            <a href='https://open.spotify.com/intl-es/artist/6RXZD4uxMt5cbmW0fN0xdn?si=suo3tVrCQ0Cz-xCqdRdpvw' target="_blank" rel='noreferrer'>
                                <FontAwesomeIcon icon={faSpotify} />
                            </a>
                        </div>
                        <div className="col">
                            <a href='https://www.tiktok.com/@losnenesdelacasavzla' target="_blank" rel='noreferrer'>
                                <FontAwesomeIcon icon={faTiktok} />
                            </a>
                        </div>
                    </div>
                </div>
                <div className="col-12 col-lg-6 d-flex align-items-center flex-column justify-content-center artist-name-container text-white">
                    <div className="col-12 d-flex align-items-center justify-content-center artist-name-container text-white py-3 bg-red">
                        <h3>
                            LOS NENES DE LA CASA
                        </h3>
                    </div>
                    <iframe className="w-100 artist-video" src="https://www.youtube.com/embed/tzHJXelj1Gc?si=P8U1JbdeHKobb4kp" title="YouTube video player" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
                </div>
            </div>
            <div className="d-flex col-12 flex-wrap flex-column-reverse flex-lg-row">
                <div className="col-12 col-lg-6 d-flex align-items-center flex-column justify-content-center artist-name-container text-white">
                    <div className="col-12 d-flex align-items-center justify-content-center artist-name-container text-white py-3 bg-red">
                        <h3>
                            CIZCO CIRCUS
                        </h3>
                    </div>
                    <iframe className="w-100 artist-video" src="https://www.youtube.com/embed/lVWTio9HozM?si=1zdomU8BIZM5B36t" title="YouTube video player" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
                </div>
                <div className="col-12 col-lg-6 artist-img-container border-top">
                    <img src={Cizco} className="artist-img" />
                    <div className="position-absolute artist-social row">
                        <div className="col">
                            <a href="https://www.instagram.com/cizcocircus/" target="_blank" rel='noreferrer'>
                                <FontAwesomeIcon icon={faInstagram} />
                            </a>
                        </div>
                        <div className="col">
                            <a href='https://open.spotify.com/intl-es/artist/5ecia27gd7uDOwF8JeQAbY?si=z9BIgJoWSS6nPlizJZJfkQ' target="_blank" rel='noreferrer'>
                                <FontAwesomeIcon icon={faSpotify} />
                            </a>
                        </div>
                    </div>
                </div>
            </div>
            <div className="d-flex col-12 flex-wrap flex-column flex-lg-row">
                <div className="col-12 col-lg-6 artist-img-container border-top">
                    <img src={Nony} className="artist-img" />
                    <div className="position-absolute artist-social row">
                        <div className="col">
                            <a href="https://www.instagram.com/nonymusica/" target="_blank" rel='noreferrer'>
                                <FontAwesomeIcon icon={faInstagram} />
                            </a>
                        </div>
                        <div className="col">
                            <a href='https://open.spotify.com/intl-es/artist/0OAQWAy0ci78WdcfneTp7d' target="_blank" rel='noreferrer'>
                                <FontAwesomeIcon icon={faSpotify} />
                            </a>
                        </div>
                    </div>
                </div>
                <div className="col-12 col-lg-6 d-flex align-items-center flex-column justify-content-center artist-name-container text-white">
                    <div className="col-12 d-flex align-items-center justify-content-center artist-name-container text-white py-3 bg-red">
                        <h3>
                            NONY MUSIC
                        </h3>
                    </div>
                    <iframe className="w-100 artist-video" src="https://www.youtube.com/embed/9GOp1Tawfb4?si=PkbGu6xb4-kigSg1" title="YouTube video player" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
                </div>
            </div>
        </div>

    )

}